<template>
  <div class="latest-news">
    <div class="news-box flex-cell m-l-10">
      <div class="news-top news-top-s flex-row txt-center">
        <div class="txt-center tabs"><i class="iconfont iconwangyesheji"></i> 最新资讯</div>
        <div class="flex-cell"></div>
        <div class="txt-center more cursor-pointer" @click="$router.push({path: '/newsLists/16/最新资讯'})">
          更多
          <i class="iconfont icon19"></i>
        </div>
      </div>
      <div class="news-cont">
        <div class="news-cell cursor-pointer" v-for="(item, index) in dataList" :key="index" @click="$router.push({name: 'newsShow', params: {id: item.ac_id,menuCode: '16',menuName: '最新资讯'}})">
          <div class="titles"><div class="tips-div">最新资讯</div>{{item.ac_title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      dataList: []
    };
  },
  created() {
    // 加载数据
    l_loadData(this)
  },
  methods: {
    
  },
};

function l_loadData(pg){
  requestGet("api/article/cates/16", {size: 5, top: 5})
    .then((res) => {
      var data = res.data
      if(data.articles.length>0){
        pg.dataList = data.articles || []
      }
    })
    .catch((error) => {
      pg.$message.error(error);
    });
}

</script>
<style lang="scss" scoped>
  .latest-news{
    .news-box{
          min-width:0;
          background: #fff;
          margin-bottom: 10px;
          padding: 20px;
          .news-top{
            font-size: 14px;
            border-bottom: 1px solid #db3e3e;
            height: 35px;
          }
          .news-top-s{
            .tabs{
              height: 29px;
              border-bottom: 6px solid #ea3e3e;
              font-size: 16px;
              .iconfont{
                color: #db3e3e;
                margin: 0 10px;
              }
            }
            .more{
              font-size: 12px;
              margin-right: 10px;
              .iconfont{
                font-size: 10px;
                margin-left: 5px;
              }
            }
            .more:hover{
              color: #db3e3e;
            }
          }
          .news-cont{
            .news-cell{
              border-bottom: 1px dotted #ddd;
              font-size: 14px;
              padding: 7px 0;
              .titles{
                line-height: 32px;
                display: inline-block;
              }
              .tips-div{
                background: #db3e3e;
                color: #fff;
                display: inline-block;
                margin-right: 10px;
                line-height: 20px;
                padding: 0 5px;
              }
            }
            .news-cell:hover{
              background: #e6f7ff;
            }
          }
        }
  }
</style>
