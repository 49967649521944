<template>
  <div class="news-components">
    <div class="news-box flex-cell m-l-10">
      <div class="news-top news-top-s flex-row txt-center">
        <div class="txt-center tabs">
          <i class="iconfont iconwangyesheji"></i> 信用导航
        </div>
        <div class="flex-cell"></div>
      </div>
      <div class="news-cont">
        <div
          class="news-child cursor-pointer"
          v-for="(item, index) in menuArr"
          :key="index"
          @click="doOpenPage(item)"
        >
          {{ item.txt }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      menuArr: [
        {
          txt: "首页",
          router: "/",
        },
        {
          txt: "政策法规",
          router: "/newsLists/7",
        },
        {
          txt: "行业资讯",
          router: "/newsLists/3",
        },
        {
          txt: "标准规范",
          router: "/newsLists/4",
        },
        {
          txt: "信用承诺",
          router: "/newsLists/5",
        },
        {
          txt: "信用服务",
          router: "/newsIndex",
        },
        {
          txt: "诚信故事",
          router: "/newsLists/8",
        },
        {
          txt: "推树活动",
          router: "/newsLists/2",
        },
      ],
    };
  },
  created() {},
  methods: {
    // 打开页面
    doOpenPage(item){
      if(item.router == '/' || item.router == '/newsIndex'){
        this.$router.push({ path: item.router})
      }else{
        this.$router.push({ path: item.router+'/'+item.txt })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.news-components {
  .news-box {
    min-width: 0;
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
    .news-top {
      font-size: 14px;
      border-bottom: 1px solid #db3e3e;
    }
    .news-top-s {
      .tabs {
        height: 29px;
        border-bottom: 6px solid #ea3e3e;
        font-size: 16px;
        .iconfont {
          color: #db3e3e;
          margin: 0 10px;
        }
      }
    }
    .news-cont {
      font-size: 14px;
      padding-top: 10px;
      .news-child {
        background: #e8e7e7;
        padding: 2px;
        display: inline-block;
        margin: 5px;
        width: calc(50% - 14px);
        text-align: center;
        color: #606060;
      }
      .news-child:hover {
        color: #000;
        background: #ddd;
      }
    }
  }
}
</style>
