<template>
  <div class="news-list" v-loading="pageLoading">
    <!-- 面包屑 -->
    <crumbs-custom :arr="TabBar" />
    <div class="cont flex-row">
      <div class="flex-cell left-box">
        <div class="tabs" v-if="tabs.length > 0">
          <div
            :class="
              index == curIdx ? 'sel_tab cursor-pointer' : 'cursor-pointer'
            "
            v-for="(item, index) in tabs"
            :key="index"
            @click="doChangeTab(index, item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="cont-list" v-if="dataList.length > 0">
          <div
            class="border-b list-cell cursor-pointer p-20"
            v-for="(item, index) in dataList"
            :key="index"
            @click="$router.push({name: 'newsShow', params: {id: item.ac_id,menuCode: params.idx,menuName: params.txt}})"
          >
            <div class="list-title">{{ item.ac_title }}</div>
            <div class="list-bref m-t-5 hidemore2">{{ item.ac_resume }}</div>
            <div class="list-other flex-row m-t-5">
              <div class="flex-cell"></div>
              <div class="">
                <i class="iconfont iconriqi"></i>发布日期：{{item.ac_pub_time.substring(0, 10)}}
              </div>
              <div class="m-l-15">
                <i class="iconfont iconlianjiewangzhi"></i>来源：
                {{ item.ac_pub_source || "" }}
              </div>
            </div>
          </div>
        </div>
        <el-empty v-if="dataList.length <= 0" description="暂无数据"></el-empty>
        <pagination
          v-if="dataList.length > 0"
          :total="total"
          :curPage="pageParams.page"
          :perPage="pageParams.size"
          @current-change="currentChange"
        />
      </div>
      <div class="right-box">
        <div class="">
          <navigation />
        </div>
        <div class="m-t-10">
          <latest-news />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import crumbsCustom from "../../components/crumbsCustom.vue";
import navigation from "./components/navigation.vue";
import latestNews from "./components/latestNews.vue";
export default {
  components: { crumbsCustom, navigation, latestNews },
  name: "newsLists",
  props: {},
  data() {
    return {
      pageLoading: false,
      tabs: [],
      curIdx: 0,
      pageParams: {
        page: 1,
        size: 10,
      },
      zoneCode: null,
      total: 0,
      dataList: [],
      idx: this.$route.params.idx,
      params: this.$route.params,
      TabBar: []
    };
  },
  mounted() {
    console.log(this.params)
    this.TabBar = [{router: '/newsLists/'+this.params.idx+'/'+this.params.txt, title: this.params.txt}]
    // this.$store.commit("CRUMBS_CUSTOM", [{title: this.$route.params.txt, router: this.$route.path}]);
    // 加载数据
    this.pageParams.page = 1;
    l_loadData(this);
  },
  watch: {
    $route (val) {
      console.log(val)
      this.TabBar = [{router: val.path, title: val.params.txt}]
    }
  },
  beforeRouteUpdate(to,from,next){
    console.log(to)
    this.idx = to.params.idx+''
    this.params = to.params
    this.tabs = []
    this.curIdx = 0
    this.pageParams.page = 1;
    // this.$store.commit("CRUMBS_CUSTOM", [{title: to.params.txt, router: to.path}]);
    next()
    l_loadData(this)
  },
  methods: {
    // @@ 改变当前页事件
    currentChange(val) {
      this.pageParams.page = val;
      l_loadData(this);
    },
    doChangeTab(idx, item) {
      this.curIdx = idx;
      this.pageParams.page = 1;
      this.zoneCode = item.value
      l_loadData(this);
    },
  },
};

// 获取数据
function l_loadData(pg) {
  pg.pageLoading = true;
  var values = pg.pageParams
  if(pg.zoneCode){
    values.zoneCode = pg.zoneCode
  }
  requestGet("api/article/cates/" + pg.idx, values)
    .then((res) => {
      var data = res.data
      if (pg.tabs.length <= 0 && pg.pageParams.page == 1) {
        pg.tabs = data.zoneTab;
      }
        pg.dataList = data.articles || [];
        pg.total = data.count || 0;
        pg.pageLoading = false;
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}
</script>
<style lang="scss" scoped>
.news-list {
  width: 1200px;
  margin: 0 auto;
  .cont {
    .left-box {
      background: #fff;
      padding: 20px;
    }
    .tabs {
      font-size: 14px;
      border-bottom: 1px solid #db3e3e;
      div {
        display: inline-block;
        padding: 7px 17px;
      }
      .sel_tab {
        color: #fff;
        background: #db3e3e;
      }
    }
    .cont-list {
      .list-cell {
        border-bottom: 1px solid #e8e8e8;
        .list-title {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }
        .list-bref {
          font-size: 14px;
          line-height: 22px;
        }
        .list-other {
          color: #606060;
          font-size: 14px;
          .iconfont {
            margin-right: 10px;
            color: #db3e3e;
            font-size: 13px;
          }
        }
      }
      .list-cell:hover {
        background: #e6f7ff;
      }
    }
    .right-box {
      width: 290px;
    }
  }
}
</style>
